const config = {
  // J&J Azure tenant ID
  tenantID: "3ac94b33-9135-4821-9502-eafda6592a35",

  //Azure App registration ID
  clientID: "83b8a045-a14d-459b-82a0-ea2e6bfe20f0",

  //MS Graph API Permissions requested for a user upon login. Default is all API permissions granted to our app
  scopes: ["https://graph.microsoft.com/.default"],

  //PROD API Gateway endpoint to our Lambda backend
  proxy: "https://0dzsnpv1c5.execute-api.us-east-1.amazonaws.com/default",
  // proxy: "https://3fzaw838ki.execute-api.us-east-1.amazonaws.com/default", (that needs key, currently getting CORS error)
  apiKey: "fByDFDeItK8lh5CMH11a2aO5Tvp8wjUsTy8QMgca",

  //Local backend endpoint
  // proxy: "http://localhost:3001",
};

export default config;
