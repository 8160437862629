import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import { useEventContext } from "../../utils/EventContext";

import Header from "../../Components/Header";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import SignIn from "../../Components/SignIn";
import { useAuthContext } from "../../utils/AuthContext";
import { UnauthenticatedTemplate } from "@azure/msal-react";
import QRCode from 'react-qr-code';

const QrCode = () => {
  const app = useAuthContext();
  const event = useEventContext();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    //Require J&J login for this page
    app.initAuthSecurePage();
  }, [app]);

  useEffect(() => {
    //only grab student or event data if user is logged in
    if (app.accountName) {
      //Upon first load, check if event config is already set or if a key was provided
      event.verifyEventConfig(queryParams, navigate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  let link = "https://www.jjtcampusrecruiting.jnj.com/?eventKey=" + event.eventConfig.EventKey

  return (
    <Container className="Student">
      <Header></Header>
      <div style={{ display: "block", textAlign: "center" }}>
        <div
          style={{
            display: "Flex",
            margin: "2em",
            justifyContent: "center",
          }}
        >
          <UnauthenticatedTemplate>
            <SignIn></SignIn>
          </UnauthenticatedTemplate>
        </div>
        <Form>
          <Form.Group>
            <QRCode 
              title="Recruiting Event"
              value={link}
              bgColor='#FFFFFF'
              fgColor="#000000"
              style={{
                      paddingLeft:"50" }}
              size='256'
            /> 
          </Form.Group>
        </Form>
      </div>
    </Container>
  );
};

export default QrCode;
